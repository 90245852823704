@font-face {
  font-family: 'Poppins';
  src: url('./common/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./common/fonts/Quicksand/static/Quicksand-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: 'Poppins';
}

.bold-header {
  font-weight: 900;
  font-size: 1.2rem;
}

.cellClassName {
  border: 1px solid #ccc;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
  background-color: transparent;
}

#apk::-webkit-file-upload-button {
  color: white;
  display: inline-block;
  background: black;
  border: none;
  padding: 7px 15px;
  font-weight: 700;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 10pt;
}

.leaflet-container {
  width: 100%;
  height: 40vh;
  z-index: 1;
}
